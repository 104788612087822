import React from "react"
import { Col, Row, Container } from "react-bootstrap"
import Section from "../base/section"
import css from "./09_footer.module.css"

import { FooterButton } from "./../controls/footerbutton"

export default function FooterSection(props) {
	return (
	<Section className={css.footerRow}>
        <Col>
            <Container fluid>
              <Row>
                <Col md={12}>
                  <h2>ODWIEDŹ NASZE SKLEPY</h2>
                </Col>
              </Row>
              <Row>
                <Col sm={12} md={6} xl={3}>
                  <FooterButton
                    text="sklepdlarolnika.pl"
                    href="https://sklepdlarolnika.pl"
                    src={`logo/sklepdlarolnika.webp`}
                  />
                </Col>
                <Col sm={12} md={6} xl={3}>
                  <FooterButton
                    text="hurt.sklepdlarolnika.pl"
                    href="https://hurt.sklepdlarolnika.pl"
                    src={`logo/hurt_min.webp`}
                  />
                </Col>
                <Col sm={12} md={6} xl={3}>
                  <FooterButton
                    text="hydrolider.pl"
                    href="https://hydrolider.pl"
                    src={`logo/hydrolider.webp`}
                  />
                </Col>
                <Col sm={12} md={6} xl={3}>
                  <FooterButton
                    text="wspomagania.pl"
                    href="https://wspomagania.pl"
                    src={`logo/wspomagania.webp`}
                  />
                </Col>
                <Col sm={12} md={12} xl={12}>
                  <FooterButton
                    text="hydraulikamobilna.pl"
                    href="https://hydraulikamobilna.pl"
                    src={`logo/hydraulikamobilna.webp`}
                  />
                </Col>
              </Row>
              <Row className={css.contactData}>
                <Col sm={12} md={4} xl={4}>
                  <h3>NUMERY KONTAKTOWE</h3>

                  <p>
                    +48 54 2844 000<br />
                    +48 609 806 296<br />
                    +48 607 730 683
                  </p>

                  
                </Col>
                <Col sm={12} md={4} xl={4} id="contact">
                  <h3>ADRESY EMAIL</h3>

                  <p>
                    <a href="mailto:sklep@agricola.com.pl">sklep@agricola.com.pl</a> <br />
                    <a href="mailto:zamawiam@agricola.com.pl">zamawiam@agricola.com.pl</a>
                  </p>

                </Col>
                <Col sm={12} md={4} xl={4}>
                  <h3>DANE FIRMY</h3>

                  <p>
                    Agricola Hydraulika Siłowa Sp. z o.o. Sp. k.<br />
                    ul. 1 Maja 40 A<br />
                    87-840 Lubień Kujawski
                  </p>

                  <p>
                    NIP: 888-313-84-50
                  </p>
                </Col>
              </Row>
              <Row>
                <Col md={12} className={css.copyright}>
                  Agricola Hydraulika Siłowa copyright 2021<sup>©</sup>
                </Col>
              </Row>
            </Container>
        </Col>
	</Section>
	)
}
