import React from "react"
import { Col, Row, Container } from "react-bootstrap"
import Section from "../base/section"
import css from "./03_shops.module.css"

import { ShopCard } from "../controls/shopcard"

import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

export default function ShopsSection(props) {
    var settings = {
        dots: true,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 4000,
        draggable: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        pauseOnHover: true,
        easing: 'ease-in-out',
        responsive: [
          {
            breakpoint: 1300,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 1000,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 680,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      };

	return (
	<Section className={css.shopsRow} id="shops">
		<Container fluid>
            <Row>
                <Col>
                    <h1 className={css.title}>SPRAWDŹ NASZE SKLEPY</h1>
                </Col>
            </Row>

            <Row>
                <Col>
                    <Slider {...settings} id="shops-slider" className={["row", css.carouselRow]}>
                        <ShopCard
                            image={`logo/sklepdlarolnika.webp`}
                            name="SKLEP DLA ROLNIKA"
                            icon={'basket.svg'}
                            label="SPRZEDAŻ DETALICZNA"
                            href="https://sklepdlarolnika.pl"
                            description={<p>Sklep stworzony dla detalicznego klienta z podstawową wiedzą o hydraulice siłowej.<ul><li>bogata oferta</li><li>pomocne materiały i opisy</li><li>wsparcie techniczne</li></ul></p>}
                        />
                        <ShopCard
                            image={`logo/hurt_min.webp`}
                            name="HURT DLA ROLNIKA"
                            icon={'paleta.webp'}
                            label="SPRZEDAŻ HURTOWA"
                            href="https://hurt.sklepdlarolnika.pl"
                            description={<p>Platforma do zakupów hurtowych dla firm i stałych klientów<ul><li>indywidualne ceny</li><li>aktualne stany magazynowe</li><li>wsparcie techniczne</li></ul></p>}
                            />
                        <ShopCard
                            image={`logo/hydrolider.webp`}
                            name="HYDROLIDER"
                            icon={'basket.svg'}
                            label="SPRZEDAŻ DETALICZNA"
                            href="https://hydrolider.pl"
                            description={<p>Sklep dedykowany czeskiej marce Hydrolider<ul><li>oryginalne markowe produkty</li><li>oficjalny sklep</li></ul></p>}
                        />
                        <ShopCard
                            image={`logo/wspomagania.webp`}
                            name="WSPOMAGANIA"
                            icon={'basket.svg'}
                            label="SPRZEDAŻ DETALICZNA"
                            href="https://wspomagania.pl"
                            description={<p>Sklep poświęcony całej gamie hydraulicznych wspomagań kierownicy przeznaczonych do ciągników rolniczych</p>}
                            />
                        <ShopCard
                            image={`logo/hydraulikamobilna.webp`}
                            name="HYDRAULIKA MOBILNA"
                            icon={'basket.svg'}
                            label="SPRZEDAŻ DETALICZNA"
                            href="https://hydraulikamobilna.pl"
                            description={<p>Sklep dla detalicznego klienta stworzony do robienia szybkich zakupów.<ul><li>proste i rzeczowe opisy</li><li>łatwy dostęp do produktu</li></ul></p>}
                            />
                    </Slider>
                </Col>
            </Row>
        </Container>
	</Section>
	)
}
