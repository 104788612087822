import React from "react"
import { Col } from "react-bootstrap"
import Section from "../base/section"
import css from "./06_eco.module.css"


export default function EcoSection(props) {
	return (
	<Section className={css.ecoRow} id="eco">
        <Col className={css.box}>
			<p>
				Jeśli kupisz produkt dobrej jakości, jego żywotność będzie dłuższa i nie będziesz go wymieniać po krótkiej eksploatacji.<br/>
				Przyczynisz się do zmniejszenia liczby produkowanych śmieci i chronisz w ten sposób środowisko naturalne.
			</p>
		</Col>
	</Section>
	)
}
