import React from "react"
import { Container } from "react-bootstrap"
import Layout from "../components/layout"

import NavSection from "../components/sections/00_navigation"
import HeaderSection from "../components/sections/01_header"
import StatsSection from "../components/sections/02_stats"
import ShopsSection from "../components/sections/03_shops"
import AdvantagesSection from "../components/sections/04_advantages"
import HistorySection from "../components/sections/05_history"
import EcoSection from "../components/sections/06_eco"
import InTouchSection from "../components/sections/07_intouch"
import SecureSection from "../components/sections/08_secure"
import FooterSection from "../components/sections/09_footer"

export default () => (
  <Layout>
    <Container fluid>
      <NavSection />
      <HeaderSection />
      <StatsSection />
      <ShopsSection />
      <AdvantagesSection />
      <HistorySection />
      <EcoSection />
      <InTouchSection />
      <SecureSection />
      <FooterSection />
    </Container>
  </Layout>
)
