import React from "react"
import { Col, Row, Container } from "react-bootstrap"
import Section from "../base/section"
import css from "./07_intouch.module.css"


export default function InTouchSection(props) {
	return (
	<Section className={css.intouchRow}>
        <Col>
            <Container fluid>
				<Row>
					<Col>
						<img src={`logo/hydrolider2.webp`} alt=""/>
						<p>JESTEŚMY DYSTRYBUTOREM ORYGINALNYCH PRODUKTÓW MARKI HYDROLIDER</p>
					</Col>
				</Row>
			</Container>
        </Col>
	</Section>
	)
}
