import React from "react"
import { Col, Row, Container } from "react-bootstrap"
import Section from "../base/section"
import css from "./05_history.module.css"

import { TyreHistory } from "../controls/tyrehistory"


export default function HistorySection(props) {
	return (
	<Section className={css.historyRow}>
		<Container fluid>
            <Row>
                <Col>
                    <h1 className={css.title}>NASZA HISTORIA</h1>
                </Col>
            </Row>
            <Row>
                <Col md={12} lg={6} xl={3} className={css.animatedTyre}>
                    <TyreHistory
                        year="1996"
                        event="ROZPOCZĘCIE DZIAŁALNOŚCI"
                    />
                </Col>
                <Col md={12} lg={6} xl={3} className={css.animatedTyre}>
                    <TyreHistory
                        year="2009"
                        event="SPRZEDAŻ INTERNETOWA"
                    />
                </Col>
                <Col md={12} lg={6} xl={3} className={css.animatedTyre}>
                    <TyreHistory
                        year="2015"
                        event="WSPÓŁPRACA Z HYDROLIDER"
                    />
                </Col>
                <Col md={12} lg={6} xl={3} className={css.animatedTyre}>
                    <TyreHistory
                        year="2021"
                        event="25 LAT NA RYNKU"
                    />
                </Col>
            </Row>
        </Container>
	</Section>
	)
}
