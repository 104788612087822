import React from "react"
import { Col, Row, Container, Image } from "react-bootstrap"
import Section from "../base/section"
import css from "./01_header.module.css"
import { AnchorLink } from "gatsby-plugin-anchor-links"

export default class HeaderSection extends React.Component {

	render() {
		return (
			<Section className={css.headerRow}>
				<Col className={css.headerBackgorund}>
					<Container fluid className={css.flexContainer}>
						<Row className={css.flexRow}>
							<Col id="imageContainer" className={css.imageContainer}>

								<Image id="header-image-engine" src={`photo/zasilacz_min.webp`} className={[css.headerImage, "d-none d-sm-inline-block"]} alt=""/>
								<Image id="header-image-controlvalve" src={`photo/rozdzielacz_min.webp`} className={[css.headerImage, "d-none d-sm-inline-block"]} alt=""/>
								<Image id="header-image-cylinder" src={`photo/silownik_min.webp`} className={[css.headerImage, "d-none d-sm-inline-block"]} alt=""/>
								
								<h1 className={css.title}>
									Sprawdzony sprzęt,<br/>
									który zostanie z Tobą na lata
								</h1>
							</Col>
						</Row>
						<Row className={css.flexRowBottom}>
							<Col>
								<AnchorLink to="/#shops" title="Sprawdź ofertę dla siebie">
									<button className={css.button}>
										SPRAWDŹ OFERTĘ DLA SIEBIE
									</button>
								</AnchorLink>
							</Col>
						</Row>
		
					</Container>
				</Col>
			</Section>
			)
	}
}