import React from "react"
import { Col } from "react-bootstrap"
import Section from "../base/section"
import css from "./08_secure.module.css"


export default function SecureSection(props) {
	return (
	<Section className={css.secureRow}>
        <Col className={css.content}>
            <h1>Z NAMI KUPUJESZ BEZPIECZNIE</h1>

            <ul>
                <li>WSZYSTKIE SKLEPY ZABEZPIECZONE CERTYFIKATEM SSL</li>
                <li>SZYFROWANE POŁĄCZENIE Z BRAMKAMI PŁATNICZYMI</li>
                <li>PRZESTRZEGAMY PRZEPISÓW RODO</li>
            </ul>
        </Col>
	</Section>
	)
}
