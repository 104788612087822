import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import css from "./statcard.module.css"

export class StatCard extends React.Component
{
	counterDuration = 1500;
	timeout = 15;
	animated = false;


	componentDidMount() {
		if(this.props.id === undefined) {
			return;
		}

		let self = this;

		document.addEventListener('scroll', function(e) {            
            let element = document.getElementById(self.props.id);
            let position = element.getBoundingClientRect();

            if(position.top >= 0 && position.bottom <= window.innerHeight && !self.animated) {
				self.animated = true;
                let counter = document.getElementById(self.props.id);
				let value = 0;
				let loops = self.counterDuration / self.timeout;
				let max = self.props.value;
				let sufix = self.props.sufix === undefined ? "" : self.props.sufix;

				let interval = setInterval(function() {
					value += max / loops;
					if(value > max) value = max;
					counter.innerHTML = parseInt(value) + sufix;
					
					if(value === max) {
						clearInterval(interval);
					}

				}, self.timeout);
			}
        });
	}

	render() {
		return (
			<Container className={css.statCardContainer}>
				<Row className={css.statCardIcon}>
					<Col>
						<img src={this.props.img} alt=""/>
					</Col>
				</Row>

				<Row className={css.statCardCounter}>
					<Col id={this.props.id}>
						{this.props.value}{this.props.sufix}
					</Col>
				</Row>

				<Row className={css.statCardLabel}>
					<Col>
						{this.props.label}
					</Col>
				</Row>

			</Container>
		)
	}
}