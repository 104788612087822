import React from "react"
import { Link } from "gatsby"
import { Image } from "react-bootstrap"
import css from "./footerbutton.module.css"

export function FooterButton(props) {
  return (
    <Link to={props.href} className={css.footer_link}>
      <div className={css.footer_button}>
        <Image src={props.src} className={css.icon} />
        <span>{props.text}</span>
      </div>
    </Link>
  )
}
