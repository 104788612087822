import React from "react"
import { Col, Row, Container } from "react-bootstrap"
import Section from "../base/section"
import css from "./04_advantages.module.css"


export default function AdvantagesSection(props) {
	return (
	<Section className={css.advantagesRow} id="advantages">
		<Container fluid>
            <Row>
                <Col className={css.skewLeft}>
                    {/* <Image src={`section_3.webp`} /> */}
                </Col>
                <Col className={css.skewRight}>
                    <div>
                        <h2>ZALETY KUPOWANIA U NAS</h2>

                        <ul className={css.list}>
                            <li>możliwość odbioru osobistego w siedzibie firmy</li>
                            <li>ponad 10 000 produktów dostępnych od ręki</li>
                            <li>kupujesz w firmie z wieloletnim doświadczeniem</li>
                            <li>program lojalnościowy</li>
                            <li>miła obsługa</li>
                            <li>szeroki wybór metod wysyłki</li>
                            <li>rozbudowana oferta</li>
                            <li>darmowe dostawy</li>
                        </ul>
                    </div>
                </Col>
            </Row>
        </Container>
	</Section>
	)
}
