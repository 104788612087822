import React from "react"
import { Col, Row, Container } from "react-bootstrap"
import Section from "../base/section"
import { NavButton } from "../controls/navbutton"
import css from "./00_navigation.module.css"

export default function NavSection(props) {
	return (
		<Section className={css.navigation}>
			<Col md={3} sm={12} className={css.navImg}>
				<img src={`logo/agricola_color_min.webp`} width="200" border="1" alt="" />
			</Col>
			<Col md={8} sm={12}>
				<Container>
					<Row className={css.navRow}>
						<Col md={3}>
							<NavButton to="/#about-us" text="O NAS" />
						</Col>
						<Col md={3}>
							<NavButton to="/#shops" text="NASZE SKLEPY" />
						</Col>
						<Col md={3}>
							<NavButton to="/#advantages" text="DLACZEGO MY" />
						</Col>
						<Col md={3}>
							<NavButton to="/#eco" text="BĄDŹ EKO" />
						</Col>
					</Row>
				</Container>
			</Col>
		</Section>
	)
}
