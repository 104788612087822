import React from "react"
import { Container, Row, Col, Image  } from "react-bootstrap"
import css from "./tyrehistory.module.css"


export function TyreHistory(props) {

  return (
      <Container className={[css.historyTyreContainer, "container"]}>
        <Image src={`tyre.webp`} fluid className={css.tyreImage}/>
        <Row className={css.historyTyreYear}>
          <Col>
            {props.year}
          </Col>
        </Row>

        <Row className={css.historyTyreEvent}>
          <Col>
            {props.event}
          </Col>
        </Row>
      </Container>
  )
}
