import React from "react"
import { Col, Row, Container } from "react-bootstrap"
import Section from "../base/section"
import css from "./02_stats.module.css"
import { StatCard } from "../controls/statcard"

class StatsSection extends React.Component {
    componentDidMount() {
 
    }

    isInViewport(element) {
        let rect = element.getBoundingClientRect();
        return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
    }

    render() {
        return (
        <Section className={css.statsRow} id="about-us">
            <Col>
                <Container fluid>
                    <Row>
                        <Col md={12} className={css.title}>
                            JESTEŚMY <span className={css.red}>LIDEREM</span> SPRZEDAŻY HYDRAULIKI SIŁOWEJ W POLSCE
                        </Col>
                        <Col md={12} lg={6} xl={4}>
                            <StatCard 
                                img={`people.svg`}
                                value="1047000"
                                label={["Tylu klientów", <br />,  "skorzystało z naszych usług"]}
                                id="counterPeople"
                            />
                        </Col>
                        <Col md={12} lg={6} xl={4}>
                            <StatCard 
                                img={`badge.svg`}
                                value="25"
                                label={["Od tylu lat", <br />,  "jesteśmy na rynku"]}
                                id="counterYears"
                            />
                        </Col>
                        <Col md={12} lg={12} xl={4}>
                            <StatCard 
                                img={`star.svg`}
                                value="98"
                                sufix=",4%"
                                label={["Tylu użytkowników", <br />,  "jest zadowolonych z zakupu"]}
                                id="counterGrade"
                            />
                        </Col>
                    </Row>
                </Container>
            </Col>
        </Section>
        )
    }
}

export default StatsSection;