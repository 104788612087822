import React from "react"
import { Container, Row, Col, Button } from "react-bootstrap"
import css from "./shopcard.module.css"

export function ShopCard(props) {
  return (
    <Container fluid className={css.shopCardContainer}>
      <Container fluid className={css.shopCard}>
        <Container className={css.shopCardFront}>
          <Row className={css.shopCardImage}>
            <Col>
              <img src={props.image} alt="" />
            </Col>
          </Row>

          <Row className={css.shopCardName}>
            <Col>
              {props.name}
            </Col>
          </Row>

          <Row className={css.shopCardIcon}>
            <Col>
              <img src={props.icon} alt=""/>
            </Col>
          </Row>

          <Row className={css.shopCardLabel}>
            <Col>
              {props.label}
            </Col>
          </Row>
        </Container>
        <Container className={css.shopCardCover}>
          <Row>
            <Col>
              <p>{props.description}</p>

              <a href={props.href}>
                <Button>ODWIEDŹ SKLEP</Button>
              </a>
            </Col>
          </Row>
        </Container>
      </Container>
    </Container>
  )
}
